import ApiJobCategoryRelation from 'types/api/JobCategoryRelation';
import { Option } from 'types/options';

import JobCategory from 'models/JobCategory';
import Model from 'models/Model';

export default class JobCategoryRelation implements Model {
    id: string;
    name: string;
    jobCategory?: JobCategory;

    constructor(data: ApiJobCategoryRelation) {
        this.id = data.id;
        this.name = data.name;
        this.jobCategory = data.jobCategory && new JobCategory(data.jobCategory);
    }

    getOption = (): Option<JobCategoryRelation> => {
        return {
            value: this,
            label: this.name,
        };
    };
}
