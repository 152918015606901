import { FunctionComponent } from 'react';
import classnames from 'classnames';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutLayoutContainer: FunctionComponent<Props> = ({ children, size, className }) => ((
    <StyledComponent
        className={classnames(
            'layout-container',
            `${className}`,
            `size-${size}`
        )}
    >
        {children}
    </StyledComponent>
));

export default LayoutLayoutContainer;