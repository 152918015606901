import { Endpoints } from 'types/endpoints';
import { ActionResponse } from 'types/redux/ActionResponse';
import { ReduxRequestPassOptions } from 'types/request';

import { withVariables } from 'utils/string';
import { reduxRequest as request } from 'services/Api';

import * as types from './types';

export interface SubmitParams {
    uniqueId: string;
}
export const submit = (params?: SubmitParams, requestParams?: ReduxRequestPassOptions) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.CREATE,
            method: 'POST',
            path: withVariables(Endpoints.PublicVisitors, params),
            params,
            requestParams: params,
            ...requestParams,
        })
    )
        .catch(error => {
            console.error(error);
            throw error;
        });
};