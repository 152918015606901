import getConfig from 'next/config';

import ApiJobCity from 'types/api/JobCity';
import { Locales } from 'types/locale';
import { Option } from 'types/options';
import { ColorStates } from 'types/states';

import City from 'models/City';
import CityDistrict from 'models/CityDistrict';
import FileUpload from 'models/FileUpload';
import Model from 'models/Model';

import { getFormattedDate, parseUtcToLocal } from 'utils/date';
import { select as selectLocale } from 'utils/locale';

export const Ability = {
    enabled: true,
    disabled: false,
};
export enum Type {
   Primary =  'primary',
    Secondary = 'secondary',
}

const translationsModels = selectLocale({
    [Locales.Pl]: require('locales/models/pl.json'),
});

export default class JobCity implements Model {
    id: string;
    createdAt: string;
    createdAtDisplay: string;
    enabled: boolean;
    enabledOption: Option<boolean>;
    city: City;
    cityDistrict: CityDistrict;
    name: string;
    type: string;
    street: string | null;
    homeNo: string | null;
    flatNo: string | null;
    postCode: string | null;
    companyFullAddress: string;
    googleMapLink: string | void;
    ogPhoto: FileUpload;

    constructor(data: ApiJobCity) {
        this.id = data.id;
        this.createdAt = data.createdAt && parseUtcToLocal(data.createdAt);
        this.createdAtDisplay = this.createdAt && getFormattedDate(this.createdAt);
        this.enabled = data.enabled;
        this.enabledOption = this.getEnabledOption(this.enabled);
        this.city = data.city && new City(data.city);
        this.cityDistrict = data.cityDistrict && new CityDistrict(data.cityDistrict);
        this.name = data.name;
        this.type = data.type;
        this.street = data.street || '';
        this.homeNo = data.homeNo || '';
        this.flatNo = data.flatNo || '';
        this.postCode = data.postCode || '';
        this.companyFullAddress = this.city?.name && `${this.city?.name}${this.street && ', ' + this.street} ${this.homeNo}`;
        this.googleMapLink = this.name && this.getGoogleMapLink() || '';
        this.ogPhoto = data.ogPhoto && new FileUpload(data.ogPhoto);
    }

    getOption = (): Option<JobCity> => {
        return {
            value: this,
            label: this.name,
        };
    };

    getEnabledOption = (value: boolean): Option<boolean> => {
        return enabledOptions.find(option => option.value === value);
    };
    getCityOption = (value: string): Option<Type> => {
        return CityOptions.find(option => option.value === value);
    };
    getGoogleMapLink = (): string | void => {
        const { publicRuntimeConfig } = getConfig();

        if(this.street && this.homeNo && this.city.name) {
            return publicRuntimeConfig.GOOGLE_MAP_LINK.replace('street', this.street).replace('homeNo', this.homeNo).replace('city', this.city.name);
        }
    };
}

export const enabledOptions = [{
    value: Ability.enabled,
    label: translationsModels?.jobs?.ability?.enabled,
    state: ColorStates.Success,
}, {
    value: Ability.disabled,
    label: translationsModels?.jobs?.ability?.disabled,
    state: ColorStates.Alert,
}];
export const CityOptions = [{
    value: Type.Primary,
    label: translationsModels?.jobCities?.types?.primary,
    state: ColorStates.Success,
}, {
    value: Type.Secondary,
    label: translationsModels?.jobCities?.types?.secondary,
    state: ColorStates.Success,
}];
