import { Locales } from 'types/locale';

import Component from './component';

export interface Props {
    children?: any;
    lang?: Locales;
}
Component.defaultProps = {
    children: null,
    lang: Locales.Pl,
};

export default Component;
