import { FunctionComponent } from 'react';
import getConfig from 'next/config';
import Script from 'next/script';

import { Props } from './index';
import StyledComponent from './styles';

const WrapperPublic: FunctionComponent<Props> = ({ children }) => {
    const { publicRuntimeConfig } = getConfig();

    return (
        <>
            <StyledComponent className="wrapper-public">
                <div className="wrapper-content">
                    {children}
                </div>
            </StyledComponent>
            {publicRuntimeConfig.FORCE_SALES_MANAGO && publicRuntimeConfig.SALES_MANAGO_ID && (
                <Script
                    strategy="lazyOnload"
                    id="sales-manago-local"
                    dangerouslySetInnerHTML={{
                        __html: `
                            var _smid="${publicRuntimeConfig.SALES_MANAGO_ID}";
                            (function(w, r, a, sm, s){
                            w['SalesmanagoObject']=r; w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)};
                            sm=document.createElement('script'); sm.type='text/javascript'; sm.async=true; sm.src=a;
                            s=document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(sm,s);
                            })(window,'sm',('https:'==document.location.protocol?'https://':'http://')+'www.salesmanago.pl/static/sm.js');
                        `,
                    }}
                />
            )}
        </>
    );
};

export default WrapperPublic;