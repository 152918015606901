import ApiJobBenefitRelation from 'types/api/JobBenefitRelation';
import { Option } from 'types/options';

import JobBenefit from 'models/JobBenefit';
import Model from 'models/Model';

export default class JobBenefitRelation implements Model {
    id: string;
    name: string;
    jobBenefit?: JobBenefit;

    constructor(data: ApiJobBenefitRelation) {
        this.id = data.id;
        this.name = data.name;
        this.jobBenefit = data.jobBenefit && new JobBenefit(data.jobBenefit);
    }

    getOption = (): Option<JobBenefitRelation> => {
        return {
            value: this,
            label: this.name,
        };
    };
}
