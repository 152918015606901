import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import { ActionResponse } from 'types/redux/ActionResponse';

import state, { initialState, State } from 'store/state';

const bindMiddleware = (middleware) => {
    if (process.env.NODE_ENV !== 'production') {
        return composeWithDevTools(applyMiddleware(...middleware));
    }

    return applyMiddleware(...middleware);
};

const combinedReducer = combineReducers(state);

const reducer = (storeState: State, action: ActionResponse) => {
    if (action.type === HYDRATE) {
        return{
            ...storeState, // use previous state
            ...action.payload, // apply delta from hydration
        };
    } else {
        return combinedReducer(storeState, action);
    }
};

export const initStore = (): any => {
    return createStore(reducer, initialState, bindMiddleware([thunkMiddleware]));
};

export const wrapper = createWrapper(initStore);