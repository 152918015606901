export enum ApiResources {
	// Me
	AuthToken = 'authToken',
	Me = 'me',

	// Misc
	Status = 'status',
	FileUpload = 'fileUpload',

	// User
	Users = 'users',
	User = 'user',
	UserCompany = 'userCompany',
	UserPasswordReset = 'userPasswordReset',
	UserRegister = 'userRegister',

	// Visitors
	Visitor = 'visitor',

	// Jobs
	Jobs = 'jobs',
	Job = 'job',

	// JobsSearchSuggestedTags
	JobsSearchSuggestedTags = 'jobsSearchSuggestedTags',

	// JobModes
	JobModes = 'jobModes',
	JobMode = 'jobMode',

	// JobAgreements
	JobAgreements = 'jobAgreements',
	JobAgreement = 'jobAgreement',

	// JobCategories
	JobCategories = 'jobCategories',
	JobCategory = 'jobCategory',

	// JobExperiences
	JobExperiences = 'jobExperiences',
	JobExperience = 'jobExperience',

	// JobTimes
	JobTimes = 'jobTimes',
	JobTime = 'jobTime',

	// JobLanguages
	JobLanguages = 'jobLanguages',
	JobLanguage = 'jobLanguage',

	// Articles
	Articles = 'articles',
	Article = 'article',

	// ArticleCategories
	ArticleCategories = 'articleCategories',

	// PricingPlans
	PricingPlans = 'pricingPlans',

	// Contacts
	Contact = 'contact',

	// Cities
	Cities = 'cities',
	City = 'city',

	// Subscribers
	Subscribers = 'subscribers',
	Subscriber = 'subscriber',

	// JobApplications
	JobApplications = 'jobApplications',
	JobApplication = 'jobApplication',

	// JobSearchesQueries
	JobSearchesQueries = 'jobSearchesQueries',
	JobSearchesQuery = 'jobSearchesQuery',

	// JobFavourites
	JobFavourites = 'jobFavourites',
	JobFavourite = 'jobFavourite',

	// Companies
	Companies = 'companies',
	Company = 'company',
	CompanyGusData = 'gusDataNew',

	// Company site
	CompanySite = 'companySite',
	CompanySites = 'companySites',

	// Faq
	FaqCategories = 'faqCategories',
	FaqPosts = 'faqPosts',

	// Track Redirect
	trackRedirect = 'trackRedirect',

	// CmsPage
	CmsPages = 'cmsPages',
	CmsPage = 'cmsPage',

	// HomePage
	HomePages = 'homePages',
	HomePage = 'homePage',

	// JobsTop
	JobsTop = 'jobs_top',
}
