import fileUploads, { initialState as fileUploadsInitialState, State as FileUploadState } from 'store/modules/fileUploads/reducer';
import user, { initialState as userInitialState, State as UserState } from 'store/modules/user/reducer';
import visitor, { initialState as visitorInitialState, State as VisitorState } from 'store/modules/visitors/reducer';

export interface State {
    visitor: VisitorState;
    user: UserState;

    fileUploads: FileUploadState;
}

export const initialState = {
    visitor: visitorInitialState,
    user: userInitialState,

    fileUploads: fileUploadsInitialState,
};

export default {
    visitor,
    user,

    fileUploads,
};