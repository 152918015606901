export enum Endpoints {
	/**
	 * Public module
	 */

	// Misc
	PublicFileUpload = '/file-uploads',

	// User
	PublicUsers = '/users',
	PublicUsersCompany = '/users/company',
	PublicUsersPassword = '/users/password',
	PublicUsersToken = '/users/token',
	PublicAdminPassword = '/users/password-admin',
	PublicUsersActivation = '/users/activation',
	PublicUsersResendActivationCode = '/users/resend-activation-code',

	// Visitors
	PublicVisitors = '/visitors',
	PublicVisitor = '/visitors/:id',

	// Jobs
	PublicJobs = '/jobs',
	PublicJob = '/jobs/:slug',
	PublicJobPreview = '/jobs/:id/preview',
	PublicTrackRedirect = '/jobs/:jobId/track-redirect',

	// JobApplications
	PublicJobApplications = '/job-applications',
	PublicJobApplication = '/job-applications/:slug',

	// JobModes
	PublicJobModes = '/job-modes',
	PublicJobMode = '/job-modes/:slug',

	// JobAgreements
	PublicJobAgreements = '/job-agreements',
	PublicJobAgreement = '/job-agreements/:slug',

	// JobCategories
	PublicJobCategories = '/job-categories',
	PublicJobCategory = '/job-categories/:slug',

	// JobExperiences
	PublicJobExperiences = '/job-experiences',
	PublicJobExperience = '/job-experiences/:slug',

	// JobLanguages
	PublicJobLanguages = '/job-languages',
	PublicJobLanguage = '/job-language/:slug',

	// JobTimes
	PublicJobTimes = '/job-times',
	PublicJobTime = '/job-times/:slug',

	// Articles
	PublicArticles = '/articles',
	PublicArticle = '/articles/:slug',

	// ArticleCategories
	PublicArticleCategories = '/article-categories',

	// PricingPlans
	PublicPricingPlans = '/pricing-plans',

	// Contacts
	PublicContacts = '/contacts',

	// Cities
	PublicCities = '/cities',
	PublicCitiesSuggest = '/cities/list-with-relation',
	PublicCity = '/cities/:id',
	PublicCityBySlug = '/cities/:slug',
	PublicCityByShortSlug = '/cities/short-slug/:slug',

	// Subscribers
	PublicSubscribers = '/subscribers',
	PublicSubscribersActivation = '/subscribers/activation',

	// Companies
	PublicCompanies = '/companies',
	PublicCompaniesGusData = '/companies/gus',

	// Company site
	PublicCompanySite = '/companies/:slug/site',
	PublicCompanySitePreview = '/company-sites/:id/preview',
	PublicCompanySites = '/company-sites',
	AdminCompanySitePublish = '/company/company-sites/:id/publish',

	// Faq
	PublicFaqCategories = '/faq-categories',
	PublicFaqPosts = '/faq-posts',

	// CmsPages
	PublicCmsPages = '/cms-pages',
	PublicCmsPage = '/cms-pages/:slug',

	// Pages
	PublicHomePage = '/pages/home-page',
	PublicSearchPage = 'pages/search-page',

	/**
	 * Auth user module
	 */

	// Me
	AuthMe = '/me',
	AuthMePassword = '/me/password',
	AuthMeUnDelete = '/worker/me/delete',

	/**
	 * Worker module
	 */

	// JobFavourites
	WorkerJobFavourites = '/worker/job-favourites',
	RemoveWorkerJobFavourites = '/worker/job-favourites/:id',

	// JobSearchesQueries
	WorkerJobSearchesQueries = '/worker/job-search-queries',
}

export interface ListParamsDefault {
	page?: number;
	perPage?: number;
	enabled?: boolean;
	search?: string;
}
