import { COOKIE_AUTH_TOKEN, COOKIE_SALES_MANGO } from 'consts/cookies';
import { getProvider } from 'services/Cookies';

export function createSession({ authToken }: { authToken: string }): any {
    getProvider().set(COOKIE_AUTH_TOKEN, authToken);
    return getSession();
}

export function getSession(): any {
    if (!validateSession()) {
        return null;
    }

    return {
        authToken: getProvider().get(COOKIE_AUTH_TOKEN),
    };
}

export function validateSession(): any {
    return Boolean(
        getProvider().get(COOKIE_AUTH_TOKEN)
    );
}

export function destroySession(): any {
    return getProvider().remove(COOKIE_AUTH_TOKEN);
}

export function createSalesMangoSession({ smId }: { smId: string }): any {
    getProvider().set(COOKIE_SALES_MANGO, smId);
    return getSalesMangoSession();
}

export function getSalesMangoSession(): any {
    if (!validateSession()) {
        return null;
    }

    return {
        smId: getProvider().get(COOKIE_SALES_MANGO),
    };
}

export function destroySalesMangoSession(): any {
    return getProvider().remove(COOKIE_SALES_MANGO);
}