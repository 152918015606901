import TagManager from 'react-gtm-module';
import getConfig from 'next/config';

import { isBrowser } from 'utils/browser';

export interface DataLayer {
    [key: string]: any;
}

export enum SmEventTypes {
    Visit = 'VISIT',
    Activation = 'ACTIVATION',
    Meeting = 'MEETING',
    Offer = 'OFFER',
    Login = 'LOGIN',
    Transaction = 'TRANSACTION',
    Other = 'OTHER',
}

export enum SmEventDescriptons {
    RegisterWorker = 'Rejestracja pracownika',
    RegisterCompany = 'Rejestracja pracodawcy',
    JobApplication = 'Pracownik aplikował na ofertę pracy przez portal',
    JobApplicationOutside = 'Pracownik aplikował na ofertę pracy przez link zewnętrzny',
    SavedJobOffer = 'Zapisanie oferty pracy',
    SharedOffer = 'Udostępnie oferty pracy w social mediach',
    SeeOnMap = 'Kliknięcie w opcję "pokaż dojazd" (przejście do zewnętrznej mapy)',
    UsedSearch = 'Skorzystanie z wyszukiwarki ofert pracy',
    BlogWorker = 'Kliknięcie w zakładkę Baza wiedzy',
    JobsTab =  'Kliknięcie w zakładkę Oferty pracy',
    Newsletter = 'Zapis do newslettera',
    BuyNow = 'Kliknięcie w Kup teraz (opcja u pracodawców)',
}

export interface Tracking {
    userId?: string;
    init: (dataLayer?: DataLayer, userId?: string, cartId?: string) => void,
    isEnabled: () => boolean,
    event: (name: string, data: DataLayer) => void,
    salesManago: (email:string, eventType: SmEventTypes, descriptions: SmEventDescriptons, location?:string, value?: number) => void,
}

const Tracking: Tracking = {
    init (dataLayer = {}) {
        if(!this.isEnabled()) return;

        const { publicRuntimeConfig } = getConfig();

        dataLayer = {
            ...dataLayer,
            originalLocation: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
        };

        TagManager.initialize({
            gtmId: publicRuntimeConfig.GTM_ID,
            dataLayer,
        });
    },

    isEnabled() {
        const { publicRuntimeConfig } = getConfig();
        return Boolean(publicRuntimeConfig.GTM_ENABLED);
    },

    event (name, data) {
        if(!this.isEnabled()) return;

        TagManager.dataLayer({
            dataLayer: {
                event: name,
                ...data,
            },
        });
    },

    salesManago (email, eventType, descriptions, location, value) {
        const { publicRuntimeConfig } = getConfig();

        const smTrack = isBrowser() && window?.['sm'] || null;

        const date = new Date();
        if (isBrowser && smTrack) smTrack('extEvent', {
            owner: publicRuntimeConfig.SALES_MANAGO_OWNER,
            email,
            contactExtEventType: eventType,
            date: date.getTime(),
            description: descriptions,
            location,
            value,
        });
    },
};

export default Tracking;