export const LOGIN = 'user/LOGIN';
export const LOGOUT = 'user/LOGOUT';
export const PROFILE_GET = 'user/PROFILE_GET';
export const PASSWORD_RESET = 'user/PASSWORD_RESET';
export const PASSWORD_SET = 'user/PASSWORD_SET';
export const SET_AUTH_TOKEN = 'user/SET_AUTH_TOKEN';
export const REGISTER = 'user/REGISTER';
export const REGISTER_COMPANY = 'user/REGISTER_COMPANY';
export const ACTIVATION = 'user/ACTIVATION';
export const RESEND_ACTIVATION_CODE = 'user/RESEND_ACTIVATION_CODE';
export const UN_DELETE = 'user/UN_DELETE';
