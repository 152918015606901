import ApiJobBenefit from 'types/api/JobBenefit';
import { Option } from 'types/options';

import Model from 'models/Model';

export default class JobBenefit implements Model {
    id: string;
    name: string;
    slug: string;

    constructor(data: ApiJobBenefit) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
    }

    getOption = (): Option<JobBenefit> => {
        return {
            value: this,
            label: this.name,
        };
    };
}