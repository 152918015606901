export function isBrowser(): boolean {
    return typeof window !== 'undefined' && typeof window === 'object' ? true : false;
}

export function isSafari():boolean {
    return (navigator.vendor.match(/apple/i) || '').length > 0;
}

export function isIos():boolean {
    return navigator.userAgent.match(/(iPhone|iPad)/g) ? true : false;
}
