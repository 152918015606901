import ApiUserRegister from 'types/api/UserRegister';

import Model from 'models/Model';
import { RegisterTypes } from 'models/User';

import { getFormattedDate, parseUtcToLocal } from 'utils/date';

export default class UserRegister implements Model {
    id: string;
    type: RegisterTypes;
    validTo: string;
    validToDisplay: string;

    constructor(data: ApiUserRegister) {
        this.id = data.id;
        this.type = data.type;
        this.validTo = data.validTo && parseUtcToLocal(data.validTo);
        this.validToDisplay = this.validTo && getFormattedDate(this.validTo);
    }
}