import Component from './component';

export interface Props {
    className?: string;
    children?: any;
    size?: Sizes;
}

export enum Sizes {
    ExtraSmall = 'extra-small',
    XXSmall = 'xxsmall',
    XSmall = 'xsmall',
    Small = 'small',
    XXMedium = 'xxmedium',
    XMedium = 'xmedium',
    Medium = 'medium',
    Large = 'large',
}

Component.defaultProps = {
    className: null,
    children: null,
    size: Sizes.Medium,
};
export default Component;