import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Locales } from 'types/locale';
import { ApiResponseBody, ReduxRequestPassOptions } from 'types/request';

import User from 'models/User';
import Visitor from 'models/Visitor';

import { profileGet, setAuthToken } from 'store/modules/user/actions';
import {
    submit as submitVisitor,
    SubmitParams as SubmitVisitorParams,
} from 'store/modules/visitors/actions';
import { State as StoreState } from 'store/state';

import Component from './component';

export interface Props {
    children?: any;
    lang: Locales;
    actions: {
        profileGet: Function;
        submitVisitor: (params?: SubmitVisitorParams, requestParams?: ReduxRequestPassOptions) => Promise<ApiResponseBody>;
        setAuthToken: (params: object) => Promise<any>;
    };
    state: {
        visitor: Visitor;
        authToken?: string;
        profile?: User;
    }
}

const mapStateToProps = (state: StoreState, ownProps?: Props): object => {
    return {
        state: {
            visitor: state?.visitor?.data,
            authToken: state.user.authToken,
            profile: state.user.profile,
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch): object => {
    return {
        actions: {
            submitVisitor: bindActionCreators(submitVisitor, dispatch),
            profileGet: bindActionCreators(profileGet, dispatch),
            setAuthToken: bindActionCreators(setAuthToken, dispatch),
        },
    };
};

Component.defaultProps = {
    children: null,
    lang: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
