import ApiJobLanguage from 'types/api/JobLanguage';
import { Locales } from 'types/locale';
import { Option } from 'types/options';

import Model from 'models/Model';

import { select as selectLocale } from 'utils/locale';

export enum Level {
    Basic = 'basic',
    Intermediate = 'intermediate',
    Advanced = 'advanced',

}
const translationsModels = selectLocale({
    [Locales.Pl]: require('locales/models/pl.json'),
});

export default class JobLanguage implements Model {
    id: string;
    name: string;
    slug: string;
    languageLevel: Level;
    languageLevelOption: Option<Level>;

    constructor(data: ApiJobLanguage) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.languageLevel = data.languageLevel;
        this.languageLevelOption = this.getLanguageLevelOption(this.languageLevel);
    }


    getLanguageLevelOption = (value: string): Option<Level> => {
        return levelOptions.find(option => option.value === value);
    };
}


export const levelOptions = [{
    value: Level.Basic,
    label: translationsModels?.jobLanguages?.types?.basic,

}, {
    value: Level.Intermediate,
    label: translationsModels?.jobLanguages?.types?.intermediate,

}, {
    value: Level.Advanced,
    label: translationsModels?.jobLanguages?.types?.advanced,
}];
