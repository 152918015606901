import ApiJobQuestion from 'types/api/JobQuestion';
import { Locales } from 'types/locale';
import { Option } from 'types/options';
import { ColorStates } from 'types/states';

import Model from 'models/Model';

import { select as selectLocale } from 'utils/locale';

export enum Types {
    TextLine = 'textLine',
    TextMultiline = 'textMultiline',
    Select = 'select',
    MultiSelect = 'multiSelect',
}

const translationsModels = selectLocale({
    [Locales.Pl]: require('locales/models/pl.json'),
});

export default class JobQuestion implements Model {
    id: string;
    question: string;
    questionType: Types;
    questionTypeOption: Option<Types>;
    options: Option<string>[];
    isRequired: boolean;

    constructor(data: ApiJobQuestion) {
        this.id = data.id;
        this.question = data.question;
        this.questionType = data.questionType;
        this.questionTypeOption = this.getQuestionTypeOption(this.questionType);
        this.options = Array.isArray(data.options)
            ? data.options.map(element => ({
                label: element?.label,
                value: element?.value,
            }))
            : [];
        this.isRequired = data.isRequired;
    }

    getOption = (): Option<JobQuestion> => {
        return {
            value: this,
            label: this.question,
        };
    };

    getQuestionTypeOption = (value: string): Option<Types> => {
        return typeOptions.find(option => option.value === value);
    };
}

export const typeOptions = [{
    value: Types.TextLine,
    label: translationsModels?.jobQuestion?.types?.textLine,
    state: ColorStates.Success,
}, {
    value: Types.TextMultiline,
    label: translationsModels?.jobQuestion?.types?.textMultiline,
    state: ColorStates.Success,
}, {
    value: Types.Select,
    label: translationsModels?.jobQuestion?.types?.select,
    state: ColorStates.Success,
}, {
    value: Types.MultiSelect,
    label: translationsModels?.jobQuestion?.types?.multiSelect,
    state: ColorStates.Success,
}];