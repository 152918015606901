export default {
    routes: require('./routes.json'),
    navigation: require('./navigation.json'),
    navigationCompany: require('./navigationCompany.json'),
    home: require('./home.json'),
    jobs: require('./jobs.json'),
    job: require('./job.json'),
    jobPreview: require('./jobPreview.json'),
    jobApplication: require('./jobApplication.json'),
    jobApplicationTYP: require('./jobApplicationTYP.json'),
    company: require('./company.json'),
    passwordReset: require('./passwordReset.json'),
    passwordResetAdmin: require('./passwordResetAdmin.json'),
    registerEmployee: require('./registerEmployee.json'),
    registerCompany: require('./registerCompany.json'),
    activationAccount: require('./activationAccount.json'),
    accountActivated: require('./accountActivated.json'),
    articlesEmployeeList: require('./articlesEmployeeList.json'),
    articlesEmployeeCategories: require('./articlesEmployeeCategories.json'),
    articleEmployee: require('./articleEmployee.json'),
    employerArticlesList: require('./employerArticlesList.json'),
    employerArticle: require('./employerArticle.json'),
    notFound: require('./404.json'),
    serverError: require('./500.json'),
    faq: require('./faq.json'),
    about: require('./about.json'),
    activationNewsletter: require('./activationNewsletter.json'),
    companySite: require('./companySite.json'),
    companySites: require('./companySites.json'),
    companyPolicy: require('./companyPolicy.json'),
    workerPolicy: require('./workerPolicy.json'),
};