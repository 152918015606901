import ApiJobAgreementRelation from 'types/api/JobAgreementRelation';
import { Option } from 'types/options';

import JobAgreement from 'models/JobAgreement';
import Model from 'models/Model';

export default class JobAgreementRelation implements Model {
    id: string;
    name: string;
    jobAgreement?: JobAgreement;

    constructor(data: ApiJobAgreementRelation) {
        this.id = data.id;
        this.name = data.name;
        this.jobAgreement = data.jobAgreement && new JobAgreement(data.jobAgreement);
    }

    getOption = (): Option<JobAgreementRelation> => {
        return {
            value: this,
            label: this.name,
        };
    };
}
