import dayjs from 'dayjs';

import ApiJob from 'types/api/Job';
import { Option } from 'types/options';

import Company from 'models/Company';
import FileUpload from 'models/FileUpload';
import JobAgreementRelation from 'models/JobAgreementRelation';
import JobBenefitRelation from 'models/JobBenefitRelation';
import JobCategoryRelation from 'models/JobCategoryRelation';
import JobCity, { Type } from 'models/JobCity';
import JobExperience from 'models/JobExperience';
import JobModeRelation from 'models/JobModeRelation';
import JobQuestion from 'models/JobQuestion';
import JobSalaries from 'models/JobSalaries';
import JobSkillRelation from 'models/JobSkillRelation';
import JobTime from 'models/JobTime';
import Model from 'models/Model';

import { getFormattedDate, parseUtcToLocal } from 'utils/date';
import { changeFromCent, displayPrice, displayPriceNumber } from 'utils/math';

import JobLanguageRelation from './JobLanguageRelation';

export enum SubmitTypes {
    Url = 'url',
    Email = 'email',
}
export enum JobStatus {
    Approved = 'approved',
    Closed = 'closed',
}
export default class Job implements Model {
    id: string;
    startAt: string;
    startAtDisplay: string;
    endAt: string;
    endAtDisplay: string;
    endAtDiff: string;
    name: string;
    slug: string;
    companyName: string;
    companyDescription: string;
    companyLogo: FileUpload;
    companyFullStreet: string;
    companyFullAddress: string;
    companyPhone: string;
    googleMapLink: string | void;
    salaryFrom: number;
    salaryFromDisplay: string;
    salaryTo: number;
    salaryToDisplay: string;
    salaryFromTo: string;
    isCvRequired: boolean;
    submitType: SubmitTypes;
    submitDescription?: string;
    expectationsDescription: string;
    dutiesDescription: string;
    offerDescription: string;
    stagesDescription: string;
    legalDescription: string;
    company: Company;
    jobExperience: JobExperience;
    jobTime: JobTime;
    jobCategories: JobCategoryRelation[];
    jobAgreements: JobAgreementRelation[];
    jobSkills: JobSkillRelation[];
    jobBenefits: JobBenefitRelation[];
    jobLanguages: JobLanguageRelation[];
    jobModes: JobModeRelation[];
    jobQuestions: JobQuestion[];
    topOffer: boolean;
    jobSalaries: JobSalaries[];
    jobCities: JobCity[];
    jobHeadlines: any;
    primaryCity: JobCity;
    jobStatus: JobStatus;
    isClosed: boolean;


    constructor(data: ApiJob) {
        this.id = data.id;
        this.startAt = data.startAt && parseUtcToLocal(data.startAt);
        this.startAtDisplay = this.startAt && getFormattedDate(this.startAt, 'date');
        this.endAt = data.endAt && parseUtcToLocal(data.endAt);
        this.endAtDisplay = this.endAt && getFormattedDate(this.endAt, 'date');
        this.endAtDiff = dayjs(data.endAt).diff(new Date(), 'days').toString();
        this.name = data.name;
        this.slug = data.slug;
        this.companyName = data.companyName;
        this.companyDescription = data.companyDescription;
        this.companyLogo = data.companyLogo && new FileUpload(data.companyLogo);
        this.jobSalaries = Array.isArray(data.jobSalaries)
            ? data.jobSalaries.map(element => new JobSalaries(element))
            : [];
        this.salaryFrom = data.salaryFrom && changeFromCent(data.salaryFrom)
            || this.jobSalaries && this.jobSalaries.length > 0 && this.jobSalaries[0].salaryFrom;
        this.salaryFromDisplay = data.salaryFrom && displayPrice(data.salaryFrom)
            || this.jobSalaries && this.jobSalaries.length > 0 && displayPrice(this.jobSalaries[0].salaryFrom);
        this.salaryTo = data.salaryTo && changeFromCent(data.salaryTo)
            || this.jobSalaries && this.jobSalaries.length > 0 && this.jobSalaries[0].salaryTo;
        this.salaryToDisplay = data.salaryTo && displayPrice(data.salaryTo)
            || this.jobSalaries && this.jobSalaries.length > 0 && displayPrice(this.jobSalaries[0].salaryTo);
        this.salaryFromTo = this.jobSalaries && this.jobSalaries.length > 0
            ? this.getBiggerSalary(this.jobSalaries)
            : this.salaryTo > 0 && `${displayPriceNumber(this.salaryFrom)} - ${displayPriceNumber(this.salaryTo)}`;
        this.isCvRequired = data.isCvRequired;
        this.submitType = data.submitType;
        this.submitDescription = data.submitDescription;
        this.expectationsDescription = data.expectationsDescription;
        this.dutiesDescription = data.dutiesDescription;
        this.offerDescription = data.offerDescription;
        this.stagesDescription = data.stagesDescription;
        this.legalDescription = data.legalDescription;
        this.legalDescription = data.legalDescription;
        this.jobExperience = data.jobExperience && new JobExperience(data.jobExperience);
        this.jobTime = data.jobTime && new JobTime(data.jobTime);
        this.jobCategories = Array.isArray(data.jobCategories)
            ? data.jobCategories.map(element => new JobCategoryRelation(element))
            : [];
        this.jobAgreements = Array.isArray(data.jobAgreements)
            ? data.jobAgreements.map(element => new JobAgreementRelation(element))
            : [];
        this.jobSkills = Array.isArray(data.jobSkills)
            ? data.jobSkills.map(element => new JobSkillRelation(element))
            : [];
        this.jobBenefits = Array.isArray(data.jobBenefits)
            ? data.jobBenefits.map(element => new JobBenefitRelation(element))
            : [];
        this.jobModes = Array.isArray(data.jobModes)
            ? data.jobModes.map(element => new JobModeRelation(element))
            : [];
        this.jobLanguages = Array.isArray(data.jobLanguages)
            ? data.jobLanguages.map(element => new JobLanguageRelation(element))
            : [];
        this.jobQuestions = Array.isArray(data.jobQuestions)
            ? data.jobQuestions.map(element => new JobQuestion(element))
            : [];
        this.topOffer = data.topOffer;
        this.jobCities = Array.isArray(data.jobCities)
            ? data.jobCities.map(element => new JobCity(element))
            : [];

        this.jobHeadlines = data?.jobHeadlines;

        this.companyPhone = data?.companyPhone && data?.companyPhone;
        this.primaryCity = Array.isArray(this.jobCities)
            ? this.jobCities.find(jobCity => jobCity.type === Type.Primary)
            : null;
        this.company = data.company && new Company(data.company);
        this.jobStatus = data.jobStatus;
        this.isClosed = this.jobStatus && this.jobStatus === JobStatus.Closed ;
    }

    getOption = (): Option<Job> => {
        return {
            value: this,
            label: this.name,
        };
    };

    getBiggerSalary = (salaries: JobSalaries[]): string | null => {
        const salary = salaries.reduce((prev, curr) => prev.salaryTo >= curr.salaryTo ? prev : curr);

        return `${displayPriceNumber(salary.salaryFrom)} - ${displayPriceNumber(salary.salaryTo)}`;
    };
}
