import ApiJobExperience from 'types/api/JobExperience';
import { Option } from 'types/options';

import Model from 'models/Model';

export default class JobExperience implements Model {
    id: string;
    name: string;
    slug: string;

    constructor(data: ApiJobExperience) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
    }

    getOption = (): Option<JobExperience> => {
        return {
            value: this,
            label: this.name,
        };
    };
}
