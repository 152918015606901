import ApiJobModeRelation from 'types/api/JobModeRelation';
import { Option } from 'types/options';

import JobMode from 'models/JobMode';
import Model from 'models/Model';

export default class JobModeRelation implements Model {
    id: string;
    name: string;
    jobMode?: JobMode;

    constructor(data: ApiJobModeRelation) {
        this.id = data.id;
        this.name = data.name;
        this.jobMode = data.jobMode && new JobMode(data.jobMode);
    }

    getOption = (): Option<JobModeRelation> => {
        return {
            value: this,
            label: this.name,
        };
    };
}
