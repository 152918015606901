import { Component, ReactNode } from 'react';

import { Locales } from 'types/locale';

import { COOKIE_AUTH_TOKEN, COOKIE_VISITOR } from 'consts/cookies';
import { isBrowser } from 'utils/browser';
import { getLocale } from 'utils/locale';
import { generateHash } from 'utils/security';
import { getProvider } from 'services/Cookies';
import Tracking from 'services/Tracking';

import LocaleContext from 'components/context/Locale';
import UserAgentContext, { MobileAgents } from 'components/context/UserAgent';
import InternetConnectionIndicator from 'components/layout/InternetConnectionIndicator';
import WrapperPublic from 'components/wrappers/Public';

import { Props } from './index';
import StyledComponent from './styles';

export default class WrapperApp extends Component<Props> {
    static defaultProps = {
        children: null,
        lang: Locales.Pl,
    };


    componentDidMount = (): void => {
        const { actions } = this.props;

        let visitorUniqueId =  getProvider().get(COOKIE_VISITOR);
        if(!visitorUniqueId) {
            visitorUniqueId = generateHash();
            getProvider().set(COOKIE_VISITOR, visitorUniqueId);
        }

        actions.submitVisitor({ uniqueId: visitorUniqueId });
        this.authenticate();

        const authToken = getProvider().get(COOKIE_AUTH_TOKEN) || null;
        if(authToken) {
            actions.setAuthToken({ authToken });
        }

        Tracking.init();
    };

    componentDidUpdate = (prevProps: Props): void => {
        const { state } = this.props;

        if (JSON.stringify(state.authToken) !== JSON.stringify(prevProps.state.authToken)) {
            this.authenticate();
        }
    };

    authenticate = (): void => {
        const { state, actions } = this.props;
        const { authToken, profile } = state;

        if (!authToken) {
            return;
        }

        if (!profile) {
            actions.profileGet();
        }
    };

    render = (): ReactNode => {
        const { children, lang } = this.props;

        return (
            <StyledComponent className="wrapper-app">
                <LocaleContext.Provider value={{ locale: lang, translations: getLocale(lang) }}>
                    <UserAgentContext.Provider value={ isBrowser() && MobileAgents.some(agent => navigator.userAgent.match(agent)) }>
                        <InternetConnectionIndicator />
                        <WrapperPublic lang={lang}>
                            {children}
                        </WrapperPublic>
                    </UserAgentContext.Provider>
                </LocaleContext.Provider>
            </StyledComponent>
        );
    };
}
