import css from '@emotion/css';

export default css `
@font-face {
    font-family: 'Open Sans';
    src: url('/static/fonts/opensans-regular-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
   }
   @font-face {
    font-family: 'Open Sans';
    src: url('/static/fonts/opensans-bold-webfont.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
   }
    html, body, * {
        font-family: 'Open Sans', sans-serif;
    }

    html {

    }

    html, body {
        font-size: 16px;
    }

    h1, h2, h3, h4, h5, h6, p, span {
        line-height: 120%;
    }

    .grecaptcha-badge {
		z-index: 999 !important;
        bottom: 110px !important;
	}

    @media all and (max-width: 720px) {
        .grecaptcha-badge {
            display: none;
        }
    }
`;