import ApiWorkerFiles from 'types/api/WorkerFiles';
import { Option } from 'types/options';

import FileUpload, { Types as FileUploadTypes } from 'models/FileUpload';
import Model from 'models/Model';

import { getFormattedDate, parseUtcToLocal } from 'utils/date';

export enum SubmitTypes {
    Url = 'url',
    Email = 'email',
}
export default class WorkerFiles implements Model {
    id: string;
    createdAt: string;
    createdAtDisplay: string;
    type: FileUploadTypes;
    isDefault: boolean;
    file: FileUpload;

    constructor(data: ApiWorkerFiles) {
        this.id = data.id;
        this.createdAt = data.createdAt && parseUtcToLocal(data.createdAt);
        this.createdAtDisplay = this.createdAt && getFormattedDate(this.createdAt);
        this.type = data.type,
        this.isDefault = data.isDefault,
        this.file = data.file && new FileUpload(data.file);
    }

    getOption = (): Option<WorkerFiles> => {
        return {
            value: this,
            label: this.file?.fileName,
        };
    };
}
