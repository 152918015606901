import ApiCompany from 'types/api/Company';
import { Option } from 'types/options';

import FileUpload from 'models/FileUpload';
import Model from 'models/Model';
export default class Company implements Model {
    id: string;
    name: string;
    shortName: string;
    slug: string;
    description: string;
    vatId: string;
    city: string;
    street: string;
    homeNo: string;
    flatNo: string;
    postCode: string;
    address: string;
    fullAddress: string;
    logo: FileUpload;
    jobCount: number;
    isPublishedCompanySite: boolean;
    companySite: {
        id: string;
        isPublished: boolean;
    };

    constructor(data: ApiCompany) {
        this.id = data.id;
        this.name = data.name;
        this.shortName = data.shortName;
        this.slug = data.slug;
        this.description = data.description;
        this.vatId = data.vatId;
        this.city = data.city && data.city;
        this.street = data.street;
        this.homeNo = data.homeNo;
        this.flatNo = data.flatNo;
        this.postCode = data.postCode;
        this.address = `${this.street} ${this.homeNo}/${this.flatNo}`;
        this.fullAddress = `${this.street} ${this.homeNo}/${this.flatNo}, ${this.postCode}, ${this.city}`;
        this.logo = data.logo && new FileUpload(data.logo);
        this.jobCount = data.jobCount;
        this.companySite = data.companySite;
        this.isPublishedCompanySite = data.isPublishedCompanySite;
    }
    getOption = (): Option<Company> => {
        return {
            value: this,
            label: this.name,
        };
    };
}
