import ApiVisitor from 'types/api/Visitor';

import Model from 'models/Model';

export default class Visitor implements Model {
    id: string;
    uniqueId: string;

    constructor(data: ApiVisitor) {
        this.id = data.id;
        this.uniqueId = data.uniqueId;
    }
}