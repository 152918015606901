import { Endpoints } from 'types/endpoints';
import { ActionResponse } from 'types/redux/ActionResponse';

import { RegisterTypes } from 'models/User';
import { Types as UserAgreeTypes } from 'models/UserAgree';

import { withVariables } from 'utils/string';
import { reduxRequest as request } from 'services/Api';
import { createSalesMangoSession, createSession, destroySalesMangoSession, destroySession } from 'services/Auth';

import * as types from './types';

export interface SetAuthTokenParams {
    authToken: string;
}
export const setAuthToken = (params?: SetAuthTokenParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch({
        type: types.SET_AUTH_TOKEN,
        payload: params,
    });
};


export interface LoginParams {
    email?: string;
    password?: string;
    role?: string;
    accessToken?: string;
    token?: string;
    googleId?: string;
    tokenType?:string;
    recaptchaToken?: string;
}
export const login = (params?: LoginParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: withVariables(types.LOGIN, params),
            method: 'POST',
            path: Endpoints.PublicUsersToken,
            params,
            requestParams: params,
            cache: {
                enabled: false,
            },
        })
    )
        .then(response => {
            createSession(response.payload.data);
            createSalesMangoSession(response.payload.data.me);
            return response;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export interface LogoutParams {}
export const logout = (params?: LogoutParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.LOGOUT,
            method: 'DELETE',
            path: withVariables(Endpoints.PublicUsersToken, params),
            params,
            cache: {
                enabled: false,
            },
        })
    )
        .then(response => {
            destroySession();
            destroySalesMangoSession();
            return response;
        })
        .catch(error => {
            console.error(error);

            destroySession();
            destroySalesMangoSession();
            throw error;
        });
};

export interface ProfileGetParams {}
export const profileGet = (params?: ProfileGetParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.PROFILE_GET,
            method: 'GET',
            path: withVariables(Endpoints.AuthMe, params),
            params,
            actionsOnCode: {
                401: () => dispatch(logout()),
            },
            cache: {
                enabled: false,
            },
        })
    )
        .catch(error => {
            destroySession();
            destroySalesMangoSession();

            console.error(error);
            throw error;
        });
};

export interface PasswordResetParams {
    email: string;
    recaptchaToken?: string;
}
export const passwordReset = (params?: PasswordResetParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.PASSWORD_RESET,
            method: 'DELETE',
            path: Endpoints.PublicUsersPassword,
            params,
            requestParams: params,
            cache: {
                enabled: false,
            },
        })
    )
        .catch(error => {
            console.error(error);
            throw error;
        });
};
export interface PasswordAdminResetParams {
    email: string;
    recaptchaToken?: string;
}
export const passwordAdminReset = (params?: PasswordAdminResetParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.PASSWORD_RESET,
            method: 'DELETE',
            path: Endpoints.PublicAdminPassword,
            params,
            requestParams: params,
            cache: {
                enabled: false,
            },
        })
    )
        .catch(error => {
            console.error(error);
            throw error;
        });
};
export interface PasswordSetParams {
    password: string;
    passwordConfirm: string;
    token: string | any;
    recaptchaToken?: string;
}
export const passwordSet = (params: PasswordSetParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.PASSWORD_SET,
            method: 'POST',
            path: Endpoints.PublicUsersPassword,
            params,
            requestParams: params,
            cache: {
                enabled: false,
            },
        })
    )
        .then(response => {
            createSession(response.payload.data);
            createSalesMangoSession(response.payload.data.me);

            return response;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};
export interface PasswordAdminSetParams {
    password: string;
    passwordConfirm: string;
    token: string | any;
    recaptchaToken?: string;
}
export const passwordAdminSet = (params: PasswordAdminSetParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.PASSWORD_SET,
            method: 'POST',
            path: Endpoints.PublicAdminPassword,
            params,
            requestParams: params,
            cache: {
                enabled: false,
            },
        })
    )
        .then(response => {
            createSession(response.payload.data);
            createSalesMangoSession(response.payload.data.me);

            return response;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};
export interface RegisterParams {
    email?: string;
    phone?: string;
    firstName?: string;
    lastName?: string;
    password?: string;
    registerType: RegisterTypes;
    phonePrefix?: string;
    googleId?: string;
    token?: string;
    userAgrees: {
        type: UserAgreeTypes;
        content: string;
        value: boolean;
    }[];
    recaptchaToken?: string;
}
export const register = (params?: RegisterParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: withVariables(types.REGISTER, params),
            method: 'POST',
            path: Endpoints.PublicUsers,
            params,
            requestParams: params,
            cache: {
                enabled: false,
            },
        })
    )
        .then(response => {
            createSession(response.payload.data);
            createSalesMangoSession(response.payload.data.me);

            return response;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export interface RegisterCompanyParams {
    email: string;
    phonePrefix?:string;
    phone?: string;
    firstName: string;
    lastName: string;
    password: string;
    name: string;
    shortName: string;
    vatId: string;
    cityId: string;
    city: string;
    street: string;
    homeNo: string;
    flatNo?: string;
    postCode: string;
    userAgrees: {
        type: UserAgreeTypes;
        content: string;
        value: boolean;
    }[];
    recaptchaToken?: string;
}
export const registerCompany = (params?: RegisterCompanyParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: withVariables(types.REGISTER_COMPANY, params),
            method: 'POST',
            path: Endpoints.PublicUsersCompany,
            params,
            requestParams: params,
            cache: {
                enabled: false,
            },
        })
    )
        .then(response => {
            createSession(response.payload.data);
            createSalesMangoSession(response.payload.data.me);

            return response;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export interface ActivationParams {
    code: string;
}
export const activation = (params?: ActivationParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: withVariables(types.ACTIVATION, params),
            method: 'POST',
            path: Endpoints.PublicUsersActivation,
            params,
            requestParams: params,
            cache: {
                enabled: false,
            },
        })
    )
        .then(response => {
            createSession(response.payload.data);
            createSalesMangoSession(response.payload.data.me);

            return response;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export interface ResendActivationCodeParams {
    type: string;
    userId: string;
}
export const resendActivationCode = (params?: ResendActivationCodeParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: withVariables(types.RESEND_ACTIVATION_CODE, params),
            method: 'POST',
            path: Endpoints.PublicUsersResendActivationCode,
            params,
            requestParams: params,
            cache: {
                enabled: false,
            },
        })
    )
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export const reactivation = (params: object = {}) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.UN_DELETE,
            method: 'DELETE',
            path: Endpoints.AuthMeUnDelete,
            params,
            requestParams: params,
            cache: {
                enabled: false,
            },
        })
    )
        .catch(error => {
            console.error(error);
            throw error;
        });
};