import ApiUser from 'types/api/User';

import Company from 'models/Company';
import FileUpload from 'models/FileUpload';
import JobFavourite from 'models/JobFavourite';
import Model from 'models/Model';
import UserRegister from 'models/UserRegister';
import WorkerFiles from 'models/WorkerFiles';

export enum Roles {
    Admin = 'admin',
    Company = 'company',
    Worker = 'worker',
}

export enum RegisterTypes {
    Email = 'email',
    Phone = 'phone',
    Facebook = 'facebook',
    Google = 'google',
}

export default class User implements Model {
    id: string;
    role: Roles;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    phone?: string;
    phonePrefix: string;
    hasPassword: boolean;
    shortName: string;
    company: Company;
    jobFavourites?: JobFavourite[];
    userRegister: UserRegister;
    isActive?: boolean;
    deletedFrom?: string;
    avatar?: FileUpload;
    workerFiles: WorkerFiles[];

    constructor(data: ApiUser) {
        this.id = data.id;
        this.role = data.role;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email && data.email;
        this.phone = data.phone && data.phone;
        this.phonePrefix = data.phonePrefix && data.phonePrefix;
        this.hasPassword = data.hasPassword;
        this.company = data.company && new Company(data.company);
        this.jobFavourites = Array.isArray(data.jobFavourites)
            ? data.jobFavourites.map(element => new JobFavourite(element))
            : [];
        this.userRegister = data.userRegister && new UserRegister(data.userRegister);
        this.fullName = this.getFullName();
        this.shortName = this.getShortName();
        this.isActive = data.isActive;
        this.deletedFrom = data.deletedFrom;
        this.avatar = data.avatar && new FileUpload(data.avatar);
        this.workerFiles = Array.isArray(data.workerFiles)
            ? data.workerFiles.map(element => new WorkerFiles(element))
            : [];
    }

    getShortName = (): string => {
        return `${this.firstName || '---'} ${this.lastName || '---'}`;
    };

    getFullName = (): string => {
        const details = [];
        if(this.email) details.push(this.email);
        if(this.phone) details.push(this.phone);

        return `${this.firstName || '---'} ${this.lastName || '---'} ${details.length ? `(${details.join(', ')})` : ''}`;
    };
}