import { getKeyValueByString } from 'utils/object';
import { parseToQueryString } from 'utils/querystring';

export function shorten(string: string, length: number, addDots = false): string {
    if (!string || typeof string !== 'string') {
        return '';
    }

    let newString = string.substring(0, length);
    if (string.length > length && addDots) newString = newString + '...';

    return newString;
}
export function replaceSpecialChars(string: string): string {
    if (!string || typeof string !== 'string') {
        return '';
    }

    const regex = /\//ig;
    const newString = string.replace(regex, ' / ');

    return newString;
}
export function withVariables(string: string, variables = {}, queryObject = {}, hash = ''): string {
    if (!string || typeof string !== 'string') {
        return '';
    }

    Object.keys(variables)
        .forEach(variableKey => {
            const pattern = ':variableKey'.replace('variableKey', variableKey);

            string = string.replace(pattern, variables[variableKey]);
        });

    Object.keys(variables)
        .forEach(variableKey => {
            const pattern = '[variableKey]'.replace('variableKey', variableKey);

            string = string.replace(pattern, variables[variableKey]);
        });

    string = string
        .replace('(', '')
        .replace(')', '');

    return parseToQueryString(string, queryObject) + hash;
}


export function isJsonString(jsonString: string): boolean {
    try {
        const o = JSON.parse(jsonString);
        if (o && typeof o === 'object') {
            return o;
        }
    }
    catch (e) {
        // Ignore
    }

    return false;
}

export function slugify(text: string): string {
    text = (text || '')
        .toString()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

    return text
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w-]+/g, '')       // Remove all non-word chars
        .replace(/--+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}

export function replaceVariables(string: string, values: object): string {
    const regexp = new RegExp('{{.+?}}', 'g');
    const matches = string.match(regexp);

    if(!Array.isArray(matches)) {
        return string;
    }

    matches.forEach(match => {
        let path = match.match(/\{\{(.*)\}\}/g)[0];
        path = path
            .replace('{', '')
            .replace('{', '')
            .replace('}', '')
            .replace('}', '');

        let value = getKeyValueByString(values, path);

        if(typeof value === 'undefined') {
            console.error(`Can't find value for variable ${match} at path ${path}`);
            value = '';
        }

        string = string.replace(match, value);
    });

    return string;
}

export function regexString (regex: { exec: (arg: string) => any[]; }, string: string): string[] {
    if(!string) return;

    let match = null;
    const regexedString = [];

    while( (match = regex.exec(string)) != null ) {
        regexedString.push(match[1]);
    }

    return regexedString;
}

export enum Names {
    FirstName = 'firstName',
    LastName = 'lastName',
}

export const getPartName = (fullName: string, withPart: Names): string => {
    if (!fullName) return;

    switch (withPart) {
        case Names.FirstName:
            return fullName.substring(0, fullName.indexOf(' '));
        case Names.LastName:
            return fullName.substring(fullName.indexOf(' '), fullName.length);
    }
};

export const prettierPhoneNumber = (values: string): string => {
    let result: string;
    const phoneNumber = values.replace(/\s/g, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumber.startsWith('+')) result = `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 9)} ${phoneNumber.slice(9)}`;
    else if (phoneNumber.startsWith('48')) result = `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 5)} ${phoneNumber.slice(5, 8)} ${phoneNumber.slice(8)}`;
    else if (phoneNumberLength === 9) result = `+48 ${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6)}`;
    else result = `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6)}`;

    return result;
};