
import ApiFileUpload from 'types/api/FileUpload';

import Model from 'models/Model';

export enum Types {
    UserAvatar = 'userAvatar',
    CompanyLogo = 'companyLogo',
    JobCompanyLogo = 'jobCompanyLogo',
    JobApplicationFile = 'jobApplicationFile',
    ArticleCoverImage = 'articleCoverImage',
    CvFile = 'cv',
    CertificateFiles = 'certificate',
    DefaultFiles = 'default',
}

export default class FileUpload implements Model {
    id: string;
    fileName: string;
    originalName: string;
    mimeType: string;
    putUrl?: string;
    getUrl: string;
    storagePath: string;
    imageUrl: string;

    constructor(data: ApiFileUpload) {
        this.id = data.id;
        this.fileName = data.fileName;
        this.originalName = data.originalName;
        this.mimeType = data.mimeType;
        this.putUrl = data.putUrl;
        this.getUrl = data.getUrl;
        this.storagePath = data.storagePath;
        this.imageUrl = data.imageUrl;
    }

    sendToS3 = (file: File, callbacks?: { progress?: (event: ProgressEvent<XMLHttpRequestEventTarget>) => any }): Promise<FileUpload> => {
        return new Promise((resolve, reject) => {
            try {
                const data = new FormData();
                data.append('file', file);

                const req = new XMLHttpRequest();
                req.open('PUT', this.putUrl);
                req.setRequestHeader('Access-Control-Allow-Origin', '*');
                req.setRequestHeader('Content-Disposition', `inline; filename="${this.fileName}"`);
                req.setRequestHeader('Cache-Control', 'max-age=86400, public');
                req.setRequestHeader('Pragma', 'public');
                req.setRequestHeader('Content-Type', this.mimeType);
                req.addEventListener('load', () => {
                    resolve(this);
                });
                req.upload.addEventListener('progress', (event: ProgressEvent<XMLHttpRequestEventTarget>) => {
                    if(callbacks?.progress) {
                        callbacks?.progress(event);
                    }
                });
                req.send(file);
            } catch (error: any) {
                reject(error);
            }
        });
    };
}
