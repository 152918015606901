export default {
    fontPrimary: 'Open Sans, sans-serif',

    // Font used only in headline (HomePage and CompanyPage)
    fontHeadline: 'filson-pro, sans-serif',
    fontHeadlineUrl: 'https://use.typekit.net/tvn7kgk.css',

    desktopXL: '1920px',
    desktopL: '1750px',
    desktopM: '1600px',
    desktopS: '1360px',
    desktopXS: '1200px',

    tabletL: '1024px',
    tabletS: '768px',

    mobileL: '640px',
    mobileM: '480px',
    mobileS: '360px',
    mobileXS: '281px',

    colorText: '#000000',
    colorTextLight: '#7b7b7b',
    colorTextLighter: '#c1c1c1',
    colorTextWhite: '#ffffff',

    colorBackground: '#F0F4F5',
    colorBackgroundBorder: '#CCCCCC',
    colorBackgroundBorderLight: '#ececec',

    colorContrast: '#06808E',
    colorContrastLighter: '#0bb6c9',
    colorInfo: '#1cb3b8',
    colorSuccess: '#62b81c',
    colorWarning: '#FFF282',
    colorAlert: '#b8211c',

    colorElement: '#10F5B0',
    colorBorder: '#272834',
    colorBlack: '#040404',

    colorBackgroundContrast: '#FFFFFF',
    colorBackgroundContrastDark: '#f3f3f3',
    colorBackgroundContrastDarker: '#c3c3c3',
    colorBackgroundContrastDarkest: '#949494',
    colorBackgroundContrastBorder: '#EEEEEE',
    colorBackgroundContrastBorderDarker: '#CCCCCC',
};
