import ApiCityCommune from 'types/api/CityCommune';

import Model from 'models/Model';

export default class CityCommune implements Model {
    id: string;
    name: string;
    slug: string;

    constructor(data: ApiCityCommune) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
    }
}