import ApiJobAgreement from 'types/api/JobAgreement';
import { Option } from 'types/options';

import Model from 'models/Model';

export enum JobAgreementsLabels {
    Temporary = 'Umowa o pracy tymczasowej',
    Replacement = 'Umowa na zastępstwo',
    Contract = 'Umowa o dzieło',
    JDG = 'Jednoosobowa działalność gospodarcza',
    UOP = 'Umowa o pracę',
    All = 'Umowa dowolna',
}

export default class JobAgreement implements Model {
    id: string;
    name: string;
    slug: string;

    constructor(data: ApiJobAgreement) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
    }

    getOption = (): Option<JobAgreement> => {
        return {
            value: this,
            label: this.name,
        };
    };
}
