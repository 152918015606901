import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Global } from '@emotion/core';
import { wrapper } from 'store';

import stylesBreakpoints from 'theme/styles/breakpoints';
import stylesGlobal from 'theme/styles/global';
import stylesReset from 'theme/styles/reset';
import stylesScrollbar from 'theme/styles/scrollbar';
import stylesToastify from 'theme/styles/toastify';

import WrapperApp from 'components/wrappers/App';

function WrapperRoot({ Component, pageProps }) {
    return (
        <>
            <Global
                styles={[
                    stylesReset,
                    stylesGlobal,
                    stylesBreakpoints,
                    stylesToastify,
                    stylesScrollbar,
                ]}
            />
            <ToastContainer
                newestOnTop={true}
                limit={2}
            />
            <WrapperApp {...pageProps}>
                <Component {...pageProps} />
            </WrapperApp>
        </>
    );
}

export default wrapper.withRedux(WrapperRoot);