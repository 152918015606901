import { useContext } from 'react';
import pl from 'locales/pl';
import { Locale } from 'locales/types/locale';

import { Locales } from 'types/locale';

import CmsPage from 'models/CmsPage';

import { COOKIE_NEXT_LOCALE } from 'consts/cookies';
import { LOCALE_DEFAULT } from 'consts/locales';
import { deserialize, replaceObjectVariables } from 'utils/object';
import { getProvider } from 'services/Cookies';

import LocaleContext from 'components/context/Locale';

const langMap = {
    [Locales.Pl]: pl,
};

export function getLocale(locale: Locales = null): Locale {
    const trns = langMap[locale || Locales.Pl];

    const result = deserialize({ ...trns });
    const replacedResult = replaceObjectVariables({ ...result }, { ...result });

    return replacedResult;
}

export function getRoutes(locale: Locales = null): { [key: string]: string } {
    const trns = langMap[locale || Locales.Pl];

    const result = deserialize({ ...trns });
    const replacedResult = replaceObjectVariables({ ...result }, { ...result });

    return replacedResult?.routes;
}

export const select = (translationsObject: object): any => {
    let selectedLang = null;
    try {
        selectedLang = useContext(LocaleContext);
    } catch (error) {
        selectedLang = getProvider().get(COOKIE_NEXT_LOCALE) || null;
    }


    let selectedObject = translationsObject[selectedLang];

    if(!selectedObject) {
        selectedObject = translationsObject[LOCALE_DEFAULT];
    }

    if(!selectedObject || Object.keys(selectedObject).length === 0) {
        return {};
    }

    const firstKey = Object.keys(selectedObject)[0];
    return selectedObject[firstKey];
};

/**
 * Returns the sections you are looking for in cmsPage from API
 * @param {cmsPage} cmsPage - cmsPage object from API
 * @param {string} searchSection - section you are looking for
 */
export const selectCmsPageSection = (cmsPage: CmsPage, searchSection: string): { [key:string]: any } => {
    const result = cmsPage?.sections?.find(({ slug }) => slug === searchSection)?.content || null;

    return result;
};