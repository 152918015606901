import { HYDRATE } from 'next-redux-wrapper';

import { ActionResponse } from 'types/redux/ActionResponse';
export interface State {}
export const initialState = {};

export default function FileUploadsReducer(state: State = initialState, action: ActionResponse): any {
    switch (action.type) {
        case HYDRATE:
            // Attention! This will overwrite client state! Real apps should use proper reconciliation.
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
