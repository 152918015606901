import ApiJobSalaries from 'types/api/JobSalaries';

import JobAgreement from 'models/JobAgreement';
import Model from 'models/Model';

import { parseUtcToLocal } from 'utils/date';
import { changeFromCent, displayPriceNumber } from 'utils/math';

export default class JobSalaries implements Model {
    id: string;
    jobAgreement: JobAgreement;
    agreementId: string;
    createdAt: string;
    name: string;
    salaryFrom: number;
    salaryFromDisplay: string;
    salaryTo: number;
    salaryToDisplay: string;

    constructor(data: ApiJobSalaries) {
        this.id = data.id;
        this.jobAgreement = data.jobAgreement && new JobAgreement(data.jobAgreement);
        this.agreementId = data.jobAgreement && this.jobAgreement.id;
        this.createdAt = data.createdAt && parseUtcToLocal(data.createdAt);
        this.name = data.name;
        this.salaryFrom = data.salaryFrom && changeFromCent(data.salaryFrom);
        this.salaryFromDisplay = data.salaryFrom && displayPriceNumber(this.salaryFrom);
        this.salaryTo = data.salaryTo && changeFromCent(data.salaryTo);
        this.salaryToDisplay = data.salaryFrom && displayPriceNumber(this.salaryTo);
    }
}

export interface JobSalariesObject {
    agreements: JobSalaries,
    b2b: JobSalaries,
}