import ApiJobSkillRelation from 'types/api/JobSkillRelation';
import { Option } from 'types/options';

import JobSkill from 'models/JobSkill';
import Model from 'models/Model';

export default class JobSkillRelation implements Model {
    id: string;
    name: string;
    jobSkill?: JobSkill;

    constructor(data: ApiJobSkillRelation) {
        this.id = data.id;
        this.name = data.name;
        this.jobSkill = data.jobSkill && new JobSkill(data.jobSkill);
    }

    getOption = (): Option<JobSkillRelation> => {
        return {
            value: this,
            label: this.name,
        };
    };
}
