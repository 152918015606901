import ApiCity from 'types/api/City';
import { Option } from 'types/options';

import CityCommune from 'models/CityCommune';
import CityCountry from 'models/CityCountry';
import CityDistrict from 'models/CityDistrict';
import CityState from 'models/CityState';
import FileUpload from 'models/FileUpload';
import Model from 'models/Model';

const prettierName = (name: string): string => name.charAt(0).toUpperCase() + name.slice(1);

export default class City implements Model {
    id: string;
    orginalName: string;
    name: string;
    slug: string;
    districts: CityDistrict[];
    commune: CityCommune;
    county: CityCountry;
    state: CityState;
    aditionalName: string;
    jobsCount: number;
    image?: FileUpload;
    priority: number;
    shortSlug: string;
    seoTitle: string;
    seoDescription: string;

    constructor(data: ApiCity) {
        this.id = data.id;
        this.slug = data.slug;
        this.districts = Array.isArray(data.districts)
            ? data.districts.map(district => new CityDistrict(district))
            : [];
        this.commune = data.commune && new CityCommune(data.commune);
        this.county = data.county && new CityCountry(data.county);
        this.state = data.state && new CityState(data.state);
        this.orginalName = data.name;
        this.name = data.name && prettierName(data.name);
        this.aditionalName = `${data.state?.name}, gmina: ${data.commune?.name}` || '';
        this.jobsCount = data.jobsCount;
        this.image = data.image && new FileUpload(data.image);
        this.priority = data.priority;
        this.shortSlug = data.shortSlug;
        this.seoTitle = data.seoTitle;
        this.seoDescription = data.seoDescription;
    }

    getOption = (): Option<City> => {
        return {
            value: this,
            label: this.name,
        };
    };
}