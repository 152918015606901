import ApiCityDistrict from 'types/api/CityDistrict';

import Model from 'models/Model';

export default class CityDistrict implements Model {
    id: string;
    name: string;
    slug: string;
    jobsCount: number;

    constructor(data: ApiCityDistrict) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.jobsCount = data.jobsCount;
    }
}