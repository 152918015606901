import { createContext } from 'react';
import { Locale } from 'locales/types/locale';

import { Locales } from 'types/locale';

export interface LocaleContext {
    locale: Locales;
    translations: Locale;
}

const initialState: LocaleContext = {
    locale: Locales.Pl,
    translations: null,
};

const LocaleContext = createContext(initialState);
export default LocaleContext;