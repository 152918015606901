import ApiJobLanguageRelation from 'types/api/JobLanguageRelation';
import { Locales } from 'types/locale';
import { Option } from 'types/options';

import JobLanguage, { Level } from 'models/JobLanguage';
import Model from 'models/Model';

import { select as selectLocale } from 'utils/locale';

const translationsModels = selectLocale({
    [Locales.Pl]: require('locales/models/pl.json'),
});

export default class JobLanguageRelation implements Model {
    id: string;
    name: string;
    jobLanguage?: JobLanguage | '';
    languageLevel: Level;
    languageLevelDisplay: Option<Level>;

    constructor(data: ApiJobLanguageRelation) {
        this.id = data.id;
        this.name = data.name;
        this.languageLevel = data.languageLevel;
        this.jobLanguage = data.jobLanguage && new JobLanguage(data.jobLanguage) || '';
        this.languageLevelDisplay = this.getLanguageLevelOption(this.languageLevel);
    }


    getLanguageLevelOption = (value: string): Option<Level> => {
        return levelOptions.find(option => option.value === value);
    };
}
export const levelOptions = [{
    value: Level.Basic,
    label: translationsModels?.jobLanguages?.types?.basic,
}, {
    value: Level.Intermediate,
    label: translationsModels?.jobLanguages?.types?.intermediate,
}, {
    value: Level.Advanced,
    label: translationsModels?.jobLanguages?.types?.advanced,

}];
