import ApiCityState from 'types/api/CityState';

import Model from 'models/Model';

export default class CityState implements Model {
    id: string;
    name: string;
    slug: string;

    constructor(data: ApiCityState) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
    }
}