import { HYDRATE } from 'next-redux-wrapper';

import { ApiResources } from 'types/apiResources';
import { ActionResponse } from 'types/redux/ActionResponse';

import Visitor from 'models/Visitor';

import { SUCCESS_FLAG } from 'consts/redux';

import * as types from './types';

export interface State {
    data: Visitor;
}

export const initialState = {
    data: null,
};

export default function VisitorsReducer(state: State = initialState, action: ActionResponse): any {
    switch (action.type) {
        case HYDRATE:
            // Attention! This will overwrite client state! Real apps should use proper reconciliation.
            return { ...state, ...action.payload };

        case types.CREATE + '_' + SUCCESS_FLAG:
            return {
                ...state,
                data: new Visitor(action.payload?.data[ApiResources.Visitor]),
            };

        default:
            return state;
    }
}
