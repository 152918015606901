import ApiJobFavourite from 'types/api/JobFavourite';

import Job from 'models/Job';
import JobCity from 'models/JobCity';
import Model from 'models/Model';

export default class JobFavourite implements Model {
    id: string;
    job: Job;
    cityId: string;
    jobCity: JobCity;

    constructor(data: ApiJobFavourite) {
        this.id = data.id;
        this.job = data.job && new Job(data.job);
        this.cityId = data.cityId;
        this.jobCity = data.cityId && new JobCity(data.job.jobCities.find(jobCity=>jobCity.city.id === this.cityId));
    }
}